<template>

    <ul class="de1_ul">
        <li class="de1_li"><a href="/company/asked/ask_main/item_reg" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon02.png" alt="토글">입고</span></a>
            <ul class="de2_ul">
                <li class="de2_li"><a href="/company/asked/ask_main/item_reg" class="de2_a">신청하기</a></li>
                <li class="de2_li"><a href="/company/asked/pickup_list/1" class="de2_a">입고신청내역</a></li>
                <li class="de2_li"><a href="/company/asked/return_list/1" class="de2_a">해외반송입고</a></li>
            </ul>
        </li>
        <li class="de1_li"><a href="/company/stocked/stock_list/1" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon02.png" alt="토글">물품현황</span></a>
            <ul class="de2_ul">
                <li class="de2_li"><a href="/company/stocked/stock_list/1" class="de2_a">패키지현황</a></li>
                <li class="de2_li"><a href="/company/stocked/release_list/1" class="de2_a">출고현황</a></li>
            </ul>
        </li>
        <li class="de1_li"><a href="/company/pay/pay_list/1" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon03.png" alt="토글">결제</span></a>
            <ul class="de2_ul">
                <li class="de2_li"><a href="/company/pay/pay_list/1" class="de2_a">결제현황</a></li>
                <li class="de2_li"><a href="/company/pay/deposit_list/1" class="de2_a">예치금내역</a></li>
                <li class="de2_li"><a href="/company/pay/pay_after/1" class="de2_a">후불결제내역</a></li>
                <li class="de2_li"><a href="/company/pay/settlement_statement/1" class="de2_a">정산내역서</a></li>
                <li class="de2_li"><a href="/company/pay/notice" class="de2_a">배송비안내</a></li>
            </ul>
        </li>
        <li class="de1_li"><a href="/company/mypage/member" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon04.png" alt="토글">마이페이지</span></a>
            <ul class="de2_ul">
                <li class="de2_li"><a href="/company/mypage/member" class="de2_a">기업정보(증빙정보)</a></li>
                <li class="de2_li"><a href="/company/mypage/address/addrList_in" class="de2_a">주소록</a></li>
            </ul>
        </li>
        <li class="de1_li"><a href="/company/cs/notice/nlist" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon05.png" alt="토글">고객센터</span></a>
            <ul class="de2_ul">
                <li class="de2_li"><a href="/company/cs/notice/1" class="de2_a">공지사항</a></li>
                <li class="de2_li"><a href="/company/cs/event/1" class="de2_a">이벤트</a></li>
                <li class="de2_li"><a href="/company/cs/faq" class="de2_a">FAQ</a></li>
                <li class="de2_li"><a href="/company/cs/through" class="de2_a">통관정보</a></li>
                <li class="de2_li"><a href="/company/cs/oto/1" class="de2_a">1:1문의</a></li>
            </ul>
        </li>
    </ul>


</template>

<script>
    export default {
        name: 'Gnb_basic',
    }
</script>

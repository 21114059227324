<template>
    <div v-if="show" class="term_pop pop_wrap">
      <div class="pop_outter">
        <div class="close"><img src="@/assets/img/ship/closeBtn.png" alt="" @click="priclose"></div>
        <div class="pop_inner">
          <div class="barTit">{{ termsTitle }}</div>
          <div class="term_inner">
              <ToastViewer :class="'p-toast'" :content="termsContent"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ToastViewer from "@/page/user/ToastViewer.vue";

export default {
    name: 'privacy',
    components: {ToastViewer},
    data(){
        return{
            termsContent: "",
            createdDate: "",
            termsTitle:"",
            termsType: "PRI",
        }
    },
    props: {
        closePopup: Function,
        show: { type: Boolean, default: false },
    },
    methods : {
        priclose () {
            this.closePopup();
        },
        search(){
            const termsType = this.termsType;

            this.$ajax({
                public: true,
                url: '/BdrRegister/searchTerms',
                params: {
                    termsType
                },
                callback: (result) => {
                    if(result && result.length > 0){
                        this.termsContent = result[0].termsContent;
                        this.termsTitle = result[0].name;
                        this.createdDateList = result.map((item, index) => ({
                            createdDate: item.createdDate,
                            termsContent: item.termsContent,
                            DateIndex:index
                        }));
                        if (this.createdDateList.length > 0) {
                            this.createdDateList.forEach(item => {
                            });
                        }
                    }
                },

            });
        }
    },
    created() {
        this.$utils.initComboMap(this.combo_map, () => {
            this.loadedAll = true
        })
        this.search();
    }
}
</script>
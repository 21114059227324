<template>

    <ul class="de1_ul">
        <li class="de1_li"><a href="/user/guide/service/osa" class="de1_a arrN"><span class="bln" role="link"><img src="@/assets/img/common/mg_icon01.png" alt="토글">이용가이드</span></a>
            <ul class="de2_ul">
                <li class="de2_li"><a href="/user/guide/service/osa" class="de2_a">보따리 서비스</a></li>
                <li class="de2_li"><a href="/user/guide/prohibit" class="de2_a">금지 및 제한품목</a></li>
                <li class="de2_li"><a href="/user/guide/packing" class="de2_a">패킹서비스 안내</a></li>
            </ul>
        </li>
        <li class="de1_li"><a href="/user/asked/ask_main" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon02.png" alt="토글">신청하기</span></a>
            <ul class="de2_ul">
                <li class="de2_li"><a href="/user/asked/ask_main" class="de2_a">신청하기</a></li>
                <li class="de2_li"><a href="/user/asked/askguide" class="de2_a">신청서작성가이드</a></li>
            </ul>
        </li>
        <li class="de1_li"><a href="/user/ship/stock/stockList/1" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon03.png" alt="토글">배송현황</span></a>
            <ul class="de2_ul">
                <li class="de2_li"><a href="/user/ship/stock/stockList/1" class="de2_a">입고현황</a></li>
                <li class="de2_li"><a href="/user/ship/release/releaseList/1" class="de2_a">출고현황</a></li>
                <li class="de2_li"><a href="/user/ship/pay_list/1" class="de2_a">결제현황</a></li>
            </ul>
        </li>
        <li class="de1_li"><a href="/user/mypage/member" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon04.png" alt="토글">마이페이지</span></a>
            <ul class="de2_ul">
                <li class="de2_li"><a href="/user/mypage/member" class="de2_a">기본정보</a></li>
                <li class="de2_li"><a href="/user/mypage/address/addrList_in" class="de2_a">주소록</a></li>
                <li class="de2_li"><a href="/user/mypage/wallet/point/1" class="de2_a">내지갑</a></li>
            </ul>
        </li>
        <li class="de1_li"><a href="/user/cs/notice/nlist" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon05.png" alt="토글">고객센터</span></a>
            <ul class="de2_ul">
                <li class="de2_li"><a href="/user/cs/notice/1" class="de2_a">공지사항</a></li>
                <li class="de2_li"><a href="/user/cs/event/1" class="de2_a">이벤트</a></li>
                <li class="de2_li"><a href="/user/cs/faq/1" class="de2_a">FAQ</a></li>
                <li class="de2_li">  <a href="https://m.cafe.naver.com/ca-fe/web/cafes/18936622/menus/28" target="_blank" class="de2_a">이용후기</a></li>
                <li class="de2_li"><a href="https://m.cafe.naver.com/ca-fe/web/cafes/18936622/menus/27" class="de2_a" target="_blank">통관정보</a></li>
                <li class="de2_li"><a href="/user/cs/unconfirmed/1" class="de2_a">고객미확인물품</a></li>
                <li class="de2_li"><a href="/user/cs/fba" class="de2_a">대량/FBA 문의</a></li>
                <li class="de2_li"><a href="/user/cs/oto/1" class="de2_a">1:1문의</a></li>
            </ul>
        </li>
    </ul>


</template>

<script>
    export default {
        name: 'Gnb_basic',
        
    }
</script>

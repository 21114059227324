<template>

    <footer class="footer wrap">
        <div class="container">
            <h1 class="m_logo"><a href="/"><img src="@/assets/img/common/w_logo.png" alt="보따리 익스프레스"></a></h1>
            <div class="mid_line">
                <ul class="box">
                    <li class="mgb">해외특송&우체국해외배송 보따리익스프레스</li>
                    <li>고객센터 : 1800-7024</li>
                    <li>24시간 연중무휴 카카오톡 상담</li>
                </ul>
                <ul class="box">
                    <li>상호명 : 한국표준상사</li>
                    <li>사업자등록번호 : 491-81-01728</li>
                    <li class="mgt"><a @click="seropen">이용약관</a><a @click="priopen">개인정보처리방침</a></li>
                </ul>
                <ul class="box">
                    <li><img src="@/assets/img/common/ems_logo.png" alt="EMS"></li>
                </ul>
            </div>
            <p class="copyright">Copyright ⓒ 2022 보따리해외배송 All rights reserved.</p>
        </div>

        <div class="sideBtn">
            <ul>
                <li><a href="http://boddariex.com" target="_blank"><img src="@/assets/img/user/side_btn01.png" alt="구매대행 신청"></a></li>
                <li><a href="http://pf.kakao.com/_PDGxbxj/chat" target="_blank"><img src="@/assets/img/user/side_btn02.png" alt="카카오톡 상담"></a></li>
                <li><a href="http://boddari-cost.com/" target="_blank"><img src="@/assets/img/user/side_btn03.png" alt="해외배송비 계산"></a></li>
                <li><a href="https://cafe.naver.com/freevpql/" target="_blank"><img src="@/assets/img/user/side_btn04.png" alt="보따리 카페"></a></li>
            </ul>
        </div>

        <div class="popup_wrap" :class="{active : serView}">
            <service :show="serView" :closePopup="serclose" />
        </div>
        <div class="popup_wrap" :class="{active : priView}">
            <privacy :show="priView" :closePopup="priclose" />
        </div>
    </footer>

</template>

<script>
import Privacy from "@/page/user/join/policy/privacy.vue";
import Service from "@/page/user/join/policy/service.vue";

export default {
    name: 'main-footer',
    components: {Service, Privacy},
    data() {
        return {
            priView: false,
            serView: false,
        }
    },
    methods: {
        priopen () {
            this.priView = true;
        },
        priclose () {
            this.priView = false;
        },
        seropen () {
            this.serView = true;
        },
        serclose () {
            this.serView = false;
        },
    },
}
</script>

